import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import { MSAL_CLIENT_ID, MSAL_TENANT_ID } from '@configurations/Settings';

const configuration: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT_ID}`
  },
  cache: {
    cacheLocation: 'localStorage'
  }
}

const msalInstance = new PublicClientApplication(configuration);

export default msalInstance;