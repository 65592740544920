const PUBLICURL = process.env.PUBLIC_URL;
const ENVIRONMENT = process.env.REACT_APP_ENV;
const APIURL = process.env.REACT_APP_API_URL;
const APPNAME = process.env.REACT_APP_APP_NAME;
const APPVERSION = process.env.REACT_APP_VERSION;
const APIVERSION = process.env.REACT_APP_API_VERSION;
const MSAL_TENANT_ID = process.env.REACT_APP_MSAL_TENANT_ID;
const MSAL_CLIENT_ID = process.env.REACT_APP_MSAL_CLIENT_ID;
const MS_GRAPH_SCOPE = process.env.REACT_APP_MS_GRAPH_SCOPE;
const POWERBI_SCOPE = process.env.REACT_APP_POWERBI_SCOPE;
const MSAL_SCOPE = [process.env.REACT_APP_MSAL_SCOPE, MS_GRAPH_SCOPE, POWERBI_SCOPE].join(';');
const DEFAULT_SPLIT_CHAR = process.env.REACT_APP_DEFAULT_SPLIT_CHAR;
const DATEFORMAT = process.env.REACT_APP_DATE_FORMAT;
const DATE_TIME_FORMAT = process.env.REACT_APP_DATE_TIME_FORMAT;
const FORMAL_DATE_FORMAT = process.env.REACT_APP_FORMAL_DATE_FORMAT
const FORMAL_DATE_TIME_FORMAT = process.env.REACT_APP_FORMAL_DATE_TIME_FORMAT;
const TIMEZONE_OFFSET = (new Date()).getTimezoneOffset();
const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const MS_GRAPH_API_URL = process.env.REACT_APP_MS_GRAPH_API_URL;
const MAX_FILE_SIZE_UPLOAD_LIMIT = process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD_LIMIT;
const VALID_FILE_EXTENSIONS = process.env.REACT_APP_VALID_FILE_EXTENSIONS;
const DQT_LINK_PROJECT_ID = process.env.REACT_APP_DQT_LINK_PROJECT_ID;
const IS_LOCKED = process.env.REACT_APP_IS_LOCKED;
const MPR_HANDBOOK = process.env.REACT_APP_MPR_HANDBOOK;

export {
    PUBLICURL,
    ENVIRONMENT,
    APIURL,
    APPNAME,
    APPVERSION,
    APIVERSION,
    MSAL_TENANT_ID,
    MSAL_CLIENT_ID,
    MSAL_SCOPE,
    MS_GRAPH_SCOPE,
    POWERBI_SCOPE,
    DEFAULT_SPLIT_CHAR,
    DATEFORMAT,
    DATE_TIME_FORMAT,
    FORMAL_DATE_FORMAT,
    FORMAL_DATE_TIME_FORMAT,
    TIMEZONE_OFFSET,
    CURRENT_TIMEZONE,
    MS_GRAPH_API_URL,
    MAX_FILE_SIZE_UPLOAD_LIMIT,
    VALID_FILE_EXTENSIONS,
    DQT_LINK_PROJECT_ID,
    IS_LOCKED,
    MPR_HANDBOOK
}