import { Suspense, lazy } from 'react';

import Loader from '@components/Loader';
import useAuth from '@auth/hooks/useAuth';

const Login = lazy(() => import('views/public/Login'));
const Main = lazy(() => import('views/protected/Main'));

const ParentRouter = () => {
    const { isAuthenticated } = useAuth();

    return(
        <>  
            {isAuthenticated && (
                <Suspense fallback={<Loader />}>
                    <Main />
                </Suspense>
            )}
            

            {!isAuthenticated && (
                <Suspense fallback={<Loader />}>
                    <Login />
                </Suspense>
            )}
        </>
    );
}

export default ParentRouter;