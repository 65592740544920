import { SilentRequest } from "@azure/msal-browser";
import { MSAL_SCOPE, MS_GRAPH_SCOPE } from "@configurations/Settings"
import msalInstance from './msalInstance';

const account = msalInstance.getAllAccounts()[0];

const tokenServiceConfig : {
  [key: string] : {
    requestParams: SilentRequest,
    tokenKey: string
  }
} = {
  'MSGRAPH' : {
    requestParams : {
      scopes: MS_GRAPH_SCOPE!.split(';'),
      account: account
    },
    tokenKey: 'msGraphToken'
  },
  'APP' :  {
    requestParams : {
      scopes: MSAL_SCOPE!.split(';'),
      account: account
    },
    tokenKey: 'accessToken'
    
  }
}

export default tokenServiceConfig;