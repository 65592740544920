import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import theme from 'themes/wsp-theme';
import MainRouter from 'routes/MainRouter';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
		},
	},
});

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<HashRouter>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<MainRouter />
					</ThemeProvider>
				</StyledEngineProvider>
			</HashRouter>
		</QueryClientProvider>
	);
};

export default App;
