import { CacheAxiosResponse } from 'axios-cache-interceptor';

import { MS_GRAPH_API_URL } from '@configurations/Settings';
import axios from '@helpers/Http/config';
import TokenStore from '../store/TokenStore';
import TokenService from '../service/TokenService';

const tokenService = new TokenService();

function CustomAppTokenErrorInterceptor () {
  const interceptor = axios.interceptors.response.use((response : CacheAxiosResponse) => {
    return response;
  }, async error => {
    try {
      if (error.config.url.includes(MS_GRAPH_API_URL)) {
        if (error.response.status === 401) {
          axios.interceptors.response.eject(interceptor);
          return tokenService.GetTokenSilently('MSGRAPH').then(response => {
            if (response) {
              console.log('new token');
              error.response.config.headers['Authorization'] = `Bearer ${TokenStore.getState().msGraphToken}`;
              return axios(error.response.config);
            } else {
              console.log('no token');
              TokenStore.getState().clearMsGraphToken();
            }
          }).catch(error => {
            TokenStore.getState().clearMsGraphToken();
          }).finally(() => {
            CustomAppTokenErrorInterceptor();
          })
        }
      }
    } catch (e) {
      console.error(e);
    }

    return Promise.reject(error);
  });
}

export default CustomAppTokenErrorInterceptor;