import { ReactNode } from "react";
import { MsalProvider } from "@azure/msal-react";
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

import msalInstance from '../config/msalInstance';

type Props = {
  children: ReactNode
}

// initialize the auth provider globally
Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance
});

const AuthProvider = (props: Props) => {

  return (
    <MsalProvider instance={msalInstance}>
      {props.children}
    </MsalProvider>
  );
}

export default AuthProvider;